/**
 * @generated SignedSource<<38e4a6bc61a74a3cb2552dde1a7021ce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CardDisplayStyle = "CONTAIN" | "COVER" | "PADDED" | "%future added value";
export type ChainIdentifier = "AMOY" | "APE_CHAIN" | "APE_CURTIS" | "ARBITRUM" | "ARBITRUM_GOERLI" | "ARBITRUM_NOVA" | "ARBITRUM_SEPOLIA" | "AVALANCHE" | "AVALANCHE_FUJI" | "B3" | "B3_SEPOLIA" | "BAOBAB" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BERA_CHAIN" | "BLAST" | "BLAST_SEPOLIA" | "BSC" | "BSC_TESTNET" | "ETHEREUM" | "FLOW" | "FLOW_TESTNET" | "GOERLI" | "GUNZILLA" | "GUNZILLA_TESTNET" | "KLAYTN" | "MATIC" | "MONAD_TESTNET" | "MUMBAI" | "OPTIMISM" | "OPTIMISM_GOERLI" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "RONIN" | "SAIGON_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "SEPOLIA" | "SHAPE" | "SOLANA" | "SOLDEV" | "SONEIUM" | "SONEIUM_MINATO" | "UNICHAIN" | "ZORA" | "ZORA_SEPOLIA" | "ZORA_TESTNET" | "%future added value";
export type OrderV2OrderType = "BASIC" | "CRITERIA" | "DUTCH" | "ENGLISH" | "%future added value";
export type TraitDisplayType = "AUTHOR" | "BOOST_NUMBER" | "BOOST_PERCENTAGE" | "DATE" | "NONE" | "NUMBER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AssetPage_data$data = {
  readonly nft: {
    readonly acceptHighestOffer: {
      readonly " $fragmentSpreads": FragmentRefs<"TradeStation_acceptHighestOffer">;
    };
    readonly activity: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: "AssetEventType";
        } | null;
      } | null>;
    };
    readonly animationUrl: string | null;
    readonly assetContract: {
      readonly address: string;
      readonly chain: ChainIdentifier;
      readonly " $fragmentSpreads": FragmentRefs<"CollectionLink_assetContract">;
    };
    readonly backgroundColor: string | null;
    readonly collection: {
      readonly category: {
        readonly slug: string;
      } | null;
      readonly description: string | null;
      readonly displayData: {
        readonly cardDisplayStyle: CardDisplayStyle | null;
      };
      readonly hidden: boolean;
      readonly imageUrl: string | null;
      readonly isSensitiveContent: boolean;
      readonly name: string;
      readonly slug: string;
      readonly " $fragmentSpreads": FragmentRefs<"Boost_collection" | "CollectionInspiredBy_data" | "CollectionLink_collection" | "NumericTrait_collection" | "Property_collection" | "SocialBar_data" | "useIsLiveUpdatesEnabledForCollection_collection" | "useIsRarityEnabled_collection">;
    };
    readonly creator: {
      readonly address: string;
      readonly displayName: string | null;
      readonly user: {
        readonly publicUsername: string | null;
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
    } | null;
    readonly decimals: number | null;
    readonly defaultRarityData: {
      readonly " $fragmentSpreads": FragmentRefs<"RarityIndicator_data">;
    } | null;
    readonly description: string | null;
    readonly favoritesCount: number;
    readonly hasUnlockableContent: boolean;
    readonly imageUrl: string | null;
    readonly isBiddingEnabled: {
      readonly reason: string | null;
      readonly value: boolean;
    };
    readonly isCompromised: boolean;
    readonly isCurrentlyFungible: boolean;
    readonly isDelisted: boolean;
    readonly isListable: boolean;
    readonly isOwnershipDisputed: boolean;
    readonly isReportedSuspicious: boolean;
    readonly isSensitiveContent: boolean;
    readonly isUnderReview: boolean;
    readonly largestOwner: {
      readonly owner: {
        readonly " $fragmentSpreads": FragmentRefs<"AccountLink_data">;
      };
      readonly quantity: string;
    } | null;
    readonly name: string | null;
    readonly numOwners: number;
    readonly numVisitors: number;
    readonly ownedQuantity: string | null;
    readonly relayId: string;
    readonly tokenId: string;
    readonly totalQuantity: string;
    readonly tradeSummary: {
      readonly bestAsk: {
        readonly closedAt: string | null;
        readonly maker: {
          readonly " $fragmentSpreads": FragmentRefs<"wallet_accountKey">;
        };
        readonly orderType: OrderV2OrderType;
        readonly priceType: {
          readonly usd: string;
        };
        readonly relayId: string;
        readonly " $fragmentSpreads": FragmentRefs<"PrivateListingBanner_data">;
      } | null;
      readonly bestBid: {
        readonly __typename: "OrderV2Type";
      } | null;
      readonly " $fragmentSpreads": FragmentRefs<"TradeStation_data">;
    };
    readonly traits: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly displayType: TraitDisplayType | null;
          readonly floatValue: number | null;
          readonly intValue: string | null;
          readonly relayId: string;
          readonly traitType: string;
          readonly value: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"Boost_trait" | "Date_trait" | "NumericTrait_trait" | "Property_trait">;
        } | null;
      } | null>;
    };
    readonly " $fragmentSpreads": FragmentRefs<"AssetDetails_data" | "AssetMedia_asset" | "AssetPageMediaHeader__accountInfo" | "AssetPageMediaHeader_item" | "ContentAuthenticity_data" | "ItemTrackingContext_item" | "ListingsPanel_asset" | "OffersPanel_asset" | "OrderManager_item" | "RedeemableItemCard_itemToBurn" | "SemiFungibleTradeStation_asset" | "Toolbar_asset" | "TradeStation_archetype" | "asset_display_name" | "asset_url" | "itemEvents_data">;
  };
  readonly " $fragmentSpreads": FragmentRefs<"CampaignAnnouncementModal_data" | "SemiFungibleTradeStation_bestListings" | "SemiFungibleTradeStation_bestOffers">;
  readonly " $fragmentType": "AssetPage_data";
};
export type AssetPage_data$key = {
  readonly " $data"?: AssetPage_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssetPage_data">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "contractAddress",
    "variableName": "contractAddress"
  },
  {
    "kind": "Variable",
    "name": "tokenId",
    "variableName": "tokenId"
  }
],
v1 = {
  "kind": "Literal",
  "name": "identity",
  "value": {}
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tokenId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "address",
  "storageKey": null
},
v5 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AccountLink_data"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isSensitiveContent",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "imageUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v12 = [
  (v4/*: any*/)
],
v13 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "AssetContractType",
  "kind": "LinkedField",
  "name": "assetContract",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "ChainType",
  "kind": "LinkedField",
  "name": "chain",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "identifier",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "chain"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "contractAddress"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "tokenId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssetPage_data",
  "selections": [
    {
      "alias": null,
      "args": (v0/*: any*/),
      "concreteType": "AssetType",
      "kind": "LinkedField",
      "name": "nft",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetPageMediaHeader_item"
        },
        {
          "args": [
            (v1/*: any*/),
            {
              "kind": "Literal",
              "name": "showQuantity",
              "value": false
            }
          ],
          "kind": "FragmentSpread",
          "name": "AssetPageMediaHeader__accountInfo"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "asset_display_name",
          "selections": [
            (v2/*: any*/),
            (v3/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ContentAuthenticity_data"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetContractType",
          "kind": "LinkedField",
          "name": "assetContract",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "chain",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionLink_assetContract"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountType",
          "kind": "LinkedField",
          "name": "creator",
          "plural": false,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserType",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "publicUsername",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "displayName",
              "storageKey": null
            },
            (v5/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "animationUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "backgroundColor",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "CollectionType",
          "kind": "LinkedField",
          "name": "collection",
          "plural": false,
          "selections": [
            (v6/*: any*/),
            (v7/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "DisplayDataType",
              "kind": "LinkedField",
              "name": "displayData",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cardDisplayStyle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "CategoryType",
              "kind": "LinkedField",
              "name": "category",
              "plural": false,
              "selections": [
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hidden",
              "storageKey": null
            },
            (v9/*: any*/),
            (v3/*: any*/),
            (v8/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionLink_collection"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Boost_collection"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "Property_collection"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "NumericTrait_collection"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SocialBar_data"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useIsLiveUpdatesEnabledForCollection_collection"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "useIsRarityEnabled_collection"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "CollectionInspiredBy_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "decimals",
          "storageKey": null
        },
        (v6/*: any*/),
        (v9/*: any*/),
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numVisitors",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isDelisted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isListable",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isReportedSuspicious",
          "storageKey": null
        },
        (v7/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isUnderReview",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCompromised",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isOwnershipDisputed",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AbleToType",
          "kind": "LinkedField",
          "name": "isBiddingEnabled",
          "plural": false,
          "selections": [
            (v10/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reason",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v11/*: any*/),
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hasUnlockableContent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "favoritesCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "TradeSummaryType",
          "kind": "LinkedField",
          "name": "tradeSummary",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestAsk",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "closedAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "orderType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PriceType",
                  "kind": "LinkedField",
                  "name": "priceType",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "usd",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AccountType",
                  "kind": "LinkedField",
                  "name": "maker",
                  "plural": false,
                  "selections": [
                    {
                      "kind": "InlineDataFragmentSpread",
                      "name": "wallet_accountKey",
                      "selections": (v12/*: any*/),
                      "args": null,
                      "argumentDefinitions": []
                    }
                  ],
                  "storageKey": null
                },
                (v11/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "PrivateListingBanner_data"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "OrderV2Type",
              "kind": "LinkedField",
              "name": "bestBid",
              "plural": false,
              "selections": (v13/*: any*/),
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TradeStation_data"
            }
          ],
          "storageKey": null
        },
        {
          "alias": "acceptHighestOffer",
          "args": [
            {
              "kind": "Literal",
              "name": "excludeAccountAsMaker",
              "value": true
            }
          ],
          "concreteType": "TradeSummaryType",
          "kind": "LinkedField",
          "name": "tradeSummary",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "TradeStation_acceptHighestOffer"
            }
          ],
          "storageKey": "tradeSummary(excludeAccountAsMaker:true)"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 100
            }
          ],
          "concreteType": "TraitTypeConnection",
          "kind": "LinkedField",
          "name": "traits",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "TraitTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "TraitType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    (v11/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "displayType",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "floatValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "intValue",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "traitType",
                      "storageKey": null
                    },
                    (v10/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Boost_trait"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Property_trait"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "NumericTrait_trait"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "Date_trait"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "traits(first:100)"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RarityDataType",
          "kind": "LinkedField",
          "name": "defaultRarityData",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "RarityIndicator_data"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetMedia_asset"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Toolbar_asset"
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "asset_url",
          "selections": [
            (v14/*: any*/),
            (v2/*: any*/),
            (v15/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "kind": "InlineDataFragmentSpread",
          "name": "itemEvents_data",
          "selections": [
            (v11/*: any*/),
            (v14/*: any*/),
            (v2/*: any*/),
            (v15/*: any*/)
          ],
          "args": null,
          "argumentDefinitions": []
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AssetDetails_data"
        },
        {
          "alias": null,
          "args": [
            (v1/*: any*/)
          ],
          "kind": "ScalarField",
          "name": "ownedQuantity",
          "storageKey": "ownedQuantity(identity:{})"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numOwners",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AssetOwnershipType",
          "kind": "LinkedField",
          "name": "largestOwner",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "quantity",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "AccountType",
              "kind": "LinkedField",
              "name": "owner",
              "plural": false,
              "selections": [
                (v5/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalQuantity",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isCurrentlyFungible",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "RedeemableItemCard_itemToBurn"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TradeStation_archetype"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OffersPanel_asset"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ListingsPanel_asset"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SemiFungibleTradeStation_asset"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "OrderManager_item"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ItemTrackingContext_item"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 11
            }
          ],
          "concreteType": "AssetEventTypeConnection",
          "kind": "LinkedField",
          "name": "activity",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssetEventTypeEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssetEventType",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v13/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "activity(first:11)"
        }
      ],
      "storageKey": null
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SemiFungibleTradeStation_bestListings"
    },
    {
      "args": (v0/*: any*/),
      "kind": "FragmentSpread",
      "name": "SemiFungibleTradeStation_bestOffers"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CampaignAnnouncementModal_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "23a2b99a60d7ccfc8e48027ebbabce4e";

export default node;
