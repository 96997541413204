/**
 * @generated SignedSource<<32cf474daf5c4424ea9b9bf0c67efdb1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AssetPageCollectionDelistedQuery$variables = {
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value";
  contractAddress: string;
  tokenId: string;
};
export type AssetPageCollectionDelistedQuery$data = {
  readonly nft: {
    readonly collection: {
      readonly isIpRightsTakedownDelisted: boolean;
    };
  };
};
export type AssetPageCollectionDelistedQuery = {
  response: AssetPageCollectionDelistedQuery$data;
  variables: AssetPageCollectionDelistedQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractAddress"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "contractAddress",
    "variableName": "contractAddress"
  },
  {
    "kind": "Variable",
    "name": "tokenId",
    "variableName": "tokenId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isIpRightsTakedownDelisted",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetPageCollectionDelistedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "nft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssetPageCollectionDelistedQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "nft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CollectionType",
            "kind": "LinkedField",
            "name": "collection",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f7a5ecdd1e80a97480ef52a4c69e5d46",
    "id": null,
    "metadata": {},
    "name": "AssetPageCollectionDelistedQuery",
    "operationKind": "query",
    "text": "query AssetPageCollectionDelistedQuery(\n  $tokenId: String!\n  $contractAddress: AddressScalar!\n  $chain: ChainScalar!\n) {\n  nft(tokenId: $tokenId, contractAddress: $contractAddress, chain: $chain) {\n    collection {\n      isIpRightsTakedownDelisted\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e7876441f2effe5b7e970f6798002c4f";

export default node;
