/**
 * @generated SignedSource<<7395b8e652aef542c7f66fb732b33c2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssetPageTraitFloorPricesQuery$variables = {
  chain: | "ETHEREUM" | "MATIC" | "KLAYTN" | "BSC" | "SOLANA" | "ARBITRUM" | "ARBITRUM_NOVA" | "AVALANCHE" | "OPTIMISM" | "OPTIMISM_SEPOLIA" | "RINKEBY" | "MUMBAI" | "AMOY" | "BAOBAB" | "BSC_TESTNET" | "GOERLI" | "SOLDEV" | "ARBITRUM_GOERLI" | "ARBITRUM_SEPOLIA" | "AVALANCHE_FUJI" | "OPTIMISM_GOERLI" | "SEPOLIA" | "BASE" | "BASE_GOERLI" | "BASE_SEPOLIA" | "BLAST" | "BLAST_SEPOLIA" | "GUNZILLA" | "GUNZILLA_TESTNET" | "SEI" | "SEI_DEVNET" | "SEI_TESTNET" | "ZORA" | "ZORA_TESTNET" | "ZORA_SEPOLIA" | "B3_SEPOLIA" | "FLOW" | "FLOW_TESTNET" | "APE_CURTIS"| "B3" | "APE_CHAIN" | "SONEIUM_MINATO" | "MONAD_TESTNET" | "SONEIUM" | "RONIN" | "SHAPE" | "BERA_CHAIN"  | "UNICHAIN" | "SAIGON_TESTNET" | "%future added value";
  contractAddress: string;
  tokenId: string;
};
export type AssetPageTraitFloorPricesQuery$data = {
  readonly nft: {
    readonly traits: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly relayId: string;
          readonly " $fragmentSpreads": FragmentRefs<"Property_traitWithFloorPrice">;
        } | null;
      } | null>;
    };
  };
};
export type AssetPageTraitFloorPricesQuery = {
  response: AssetPageTraitFloorPricesQuery$data;
  variables: AssetPageTraitFloorPricesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "chain"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "contractAddress"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tokenId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "chain",
    "variableName": "chain"
  },
  {
    "kind": "Variable",
    "name": "contractAddress",
    "variableName": "contractAddress"
  },
  {
    "kind": "Variable",
    "name": "tokenId",
    "variableName": "tokenId"
  }
],
v4 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "relayId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssetPageTraitFloorPricesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "nft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "TraitTypeConnection",
            "kind": "LinkedField",
            "name": "traits",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TraitTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TraitType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "Property_traitWithFloorPrice"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "traits(first:100)"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssetPageTraitFloorPricesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "AssetType",
        "kind": "LinkedField",
        "name": "nft",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v4/*: any*/),
            "concreteType": "TraitTypeConnection",
            "kind": "LinkedField",
            "name": "traits",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TraitTypeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TraitType",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v5/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PriceType",
                        "kind": "LinkedField",
                        "name": "floorPrice",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "unit",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "symbol",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "traits(first:100)"
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d3aad729150ad9cad520f1821de79a0",
    "id": null,
    "metadata": {},
    "name": "AssetPageTraitFloorPricesQuery",
    "operationKind": "query",
    "text": "query AssetPageTraitFloorPricesQuery(\n  $contractAddress: AddressScalar!\n  $tokenId: String!\n  $chain: ChainScalar!\n) {\n  nft(contractAddress: $contractAddress, tokenId: $tokenId, chain: $chain) {\n    traits(first: 100) {\n      edges {\n        node {\n          relayId\n          ...Property_traitWithFloorPrice\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment Property_traitWithFloorPrice on TraitType {\n  floorPrice {\n    unit\n    symbol\n  }\n}\n"
  }
};
})();

(node as any).hash = "b95883e5b958c63950fedcf63442a01c";

export default node;
